<template>
  <BCard>
    <BRow class="items-center">
      <BCol lg="6">
        <strong class="text-black text-bold text-2xl">{{ title }}</strong>
      </BCol>
      <BCol
        lg="6"
        class="flex justify-end items-center"
      >
        <BFormInput
          v-model="search"
          placeholder="Ketik nama user atau resi"
          style="width: 45%; margin-right: 10px"
          @input="searchData"
        />
        <BFormSelect
          v-model="expedition"
          :options="expeditionList"
          value-field="shipping_name"
          text-field="shipping_name"
          style="width: 25%"
        >
          <template #first>
            <b-form-select-option
              :value="expeditionList.map(item => item.shipping_name).join()"
            >
              Semua
            </b-form-select-option>
          </template>
        </BFormSelect>
      </BCol>
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="table"
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(pickup_request_date)="data">
          <div
            class="text-black min-w-[150px]"
            style="font-size: 13px"
          >
            {{ DAY_MONTH_YEAR(data.value) }}
          </div>
          <div class="text-[#828282]">
            {{ TIME(data.value) }} WIB
          </div>
        </template>
        <template #cell(user_name)="data">
          <div class="flex items-center">
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
              alt="Komerce"
              style="cursor: pointer"
              @click="handlePhone(data)"
            >
            <span style="color: #222222">{{ data.value }}</span>
          </div>
        </template>
        <template #cell(shipping_image)="data">
          <img
            :src="data.value"
            alt="Komerce"
            style="width: 50px; margin: auto"
          >
        </template>
        <template #cell(qty)="data">
          <div class="text-[#222222] min-w-[150px]">
            {{ data.value }}
          </div>
        </template>
        <template #cell(no_resi)="data">
          <div class="flex items-center">
            <span class="text-[#222222]">{{ data.value }}</span>
            <feather-icon
              v-b-tooltip.hover.top="'Copy resi'"
              icon="CopyIcon"
              class="cursor-pointer ml-[5px]"
              size="20"
              style="color: #222222"
              @click="copyResi(data.value)"
            />
          </div>
        </template>
        <template #cell(pickup_city)="data">
          <div class="flex items-center min-w-[150px]">
            <span style="color: #222222">{{ data.value }}</span>
            <feather-icon
              v-b-tooltip.hover.top="'Copy Detail Pickup'"
              icon="CopyIcon"
              class="cursor-pointer ml-[5px]"
              size="20"
              style="color: #222222"
              @click="copyDetailData(data.item)"
            />
          </div>
        </template>
        <template #cell(delay)="data">
          <div class="text-[#222222] min-w-[150px]">
            {{ handleDelayAction(data.value) }}
          </div>
          <div
            v-if="data.item.delay_status !== '-'"
            :class="classDelayStatus(data.item.delay_status)"
          >
            {{ data.item.delay_status }}
          </div>
        </template>
        <template #cell(order_status)="data">
          <span :class="handleClassStatus(data.value)">{{ data.value }}</span>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { komshipAxiosIns } from '@/libs/axios'
import configColumn from './config'

export default {
  data() {
    return {
      title: 'Monitoring Pickup',
      loading: true,
      filterStatus: [],
      search: '',
      items: [],
      fields: configColumn,
      DAY_MONTH_YEAR,
      TIME,
      shipping: null,
      expedition: '',
      limit: 20,
      offset: 0,
      lastData: false,
    }
  },
  computed: {
    ...mapState('filter', ['expeditionList']),
  },
  watch: {
    expedition: {
      handler() { this.getData() },
    },
  },
  async mounted() {
    if (this.expeditionList.length === 0) {
      this.$store.dispatch('filter/getUpdateExpedition')
    }
    this.expedition = this.expeditionList.map(item => item.shipping_name).join()
    await this.getData()
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('table').offsetHeight && !this.loading) {
        this.getNextData()
      }
    }
  },
  methods: {
    async getData() {
      this.loading = true
      this.offset = 0
      const params = {
        filter_shipping: this.expedition,
        limits: this.limit,
        offset: this.offset,
        search: this.search,
      }
      const url = '/v2/admin/monitoring/pickup'
      await komshipAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.items = data
          this.loading = false
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    async getNextData() {
      if (!this.lastData) {
        this.loading = true
        const params = {
          filter_shipping: this.expedition,
          limits: this.limit,
          offset: this.offset,
          search: this.search,
        }
        const url = '/v2/admin/monitoring/pickup'
        await komshipAxiosIns.get(url, { params })
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.response.message,
                variant: 'danger',
              },
            })
          })
      }
    },
    searchData: _.debounce(function search() {
      this.getData()
    }, 1000),
    handleClassStatus(value) {
      if (value === 'Dipacking') {
        return 'send'
      }
      if (value === 'Dikirim') {
        return 'packing'
      }
      return ''
    },
    handleDelayAction(value) {
      if (value) {
        return value
      }
      return ''
    },
    copyResi(value) {
      navigator.clipboard.writeText(value)
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Resi berhasil disalin',
          icon: 'CheckIcon',
          variant: 'success',
        },
      }, 1000)
    },
    copyDetailData(value) {
      const username = `Nama Penanggung Jawab: ${value.user_name}`
      const phoneNumber = `No.Hp: ${value.pickup_detail.pic_phone}`
      const resi = `Resi sample: ${value.no_resi}`
      const createdAt = `Pengajuan Pickup: ${moment(
        value.pickup_detail.pickup_date,
      ).format('DD MMMM YYYY')} Pukul ${value.pickup_detail.pickup_time} WIB`
      const pickupcity = `Kota Pickup: ${value.pickup_detail.city}`
      const address = `Alamat: ${value.pickup_detail.address_detail}`
      const data = `${username}\n${phoneNumber}\n${address}\n${pickupcity}\n${resi}\n${createdAt}`

      navigator.clipboard.writeText(data)
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Data berhasil disalin',
          icon: 'CheckIcon',
          variant: 'success',
        },
      }, 1000)
    },
    handlePhone(value) {
      const phoenNumber = value.item.user_phone
      window.open(`https://wa.me/62${phoenNumber.substring(1)}`, '_blank')
    },
    classDelayStatus(status) {
      if (status === 'Terlewati') {
        return 'border bg-[#E31A1A] text-white rounded-xl border-[#E31A1A]'
      }
      if (status === 'Kedepan') {
        return 'border bg-[#FBA63C] text-white rounded-xl border-[#FBA63C]'
      }
      return ''
    },
  },
}
</script>
<style scoped>
.send {
  color: #08a0f7;
  border: 1px solid #08a0f7;
  border-radius: 10px;
  padding: 0 10px;
  background: #dff3ff;
}
.packing {
  color: #fbbc05;
  border: 1px solid#fbbc05;
  border-radius: 10px;
  padding: 0 10px;
  background: #f8ecc8;
}
</style>
